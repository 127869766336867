import { useState } from 'react';
import './App.css';

import Container from './components/container/Container';
import Intro from './components/intro/Intro';
import Game from "./components/game/Game";

import logo from './images/tabrecta.png';

function App() {
  const [intro, setIntro] = useState(true);

  return (
    <Container>
      {intro && <Intro logo={logo} nav={() => {
        setIntro(false);
      }} />}
      {!intro && <Game />}
    </Container>
  );
}

export default App;
