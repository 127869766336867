import styles from "./Container.module.scss";

export default function Container({ children }) {
  const getScale = () => {
    const scale = Math.min(window.innerWidth / 2000, window.innerHeight / 1125);
    console.log(scale);
    return scale;
  };

  return (
    <div
      className={styles.container}
      style={{ transform: `scale(${getScale()})` }}
    >
      {children}
    </div>
  );
}
